<template>
  <div>
    <v-card-title v-if="!this.firstLoad">
      <v-btn @click="$router.back()" color="#ffffff" light elevation="0">
        <v-icon left large>mdi-chevron-left</v-icon>
      </v-btn>
      <h3>{{ form.header }}</h3>
    </v-card-title>
    <!--Loading state-->
    <v-skeleton-loader type="card-heading" v-else> </v-skeleton-loader>
  </div>
</template>
<script>
export default {
  props: ["form", "firstLoad"],
  name: "FormTitle",
  data() {
    return {};
  },
};
</script>